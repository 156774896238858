import React from 'react';
import priceIcon from '../assets/images/icon-price.png';
import qualityIcon from '../assets/images/icon-quality.png';
import deliveryIcon from '../assets/images/icon-delivery.png';
import vector from '../assets/images/Vector.png'
import vector1 from '../assets/images/Vector1.png'

const WhyChooseUs = () => (
  <>
  <section className="why-choose-us">
    <div className="why-title">
      <h2><img src={vector} alt="" /> Why Choose Us <img src={vector1} alt="" /></h2>
      <div className="why-decorator">
      </div>
    </div>
    <div className="why-cards">
      <div className="why-card">
        <img src={priceIcon} alt="Reasonable Prices" />
        <h3>Reasonable Prices</h3>
        <p>
          We know how important it is to stick to your budget. That’s why we
          offer competitive prices without lowering our quality standards. Bulk
          orders come with even better deals, making it easier for you to get
          the best value for your money. Whether you need uniforms, sportswear,
          or festive outfits, we make sure your investment is worth it.
        </p>
      </div>
      <div className="why-card">
        <img src={qualityIcon} alt="Top-Notch Quality" />
        <h3>Top-Notch Quality</h3>
        <p>
          We don’t just talk about quality—we deliver it. Every fabric we use is
          strong, comfortable, and long-lasting. Our team pays attention to
          every little detail, from stitching to finishing, to ensure you get
          only the best. Be it breathable sportswear, durable school uniforms,
          or colorful festive wear, you can count on us for excellent products.
        </p>
      </div>
      <div className="why-card">
        <img src={deliveryIcon} alt="Timely Delivery" />
        <h3>Timely Delivery</h3>
        <p>
          We understand that time is important for your business. That’s why we
          work quickly and efficiently to complete and deliver your orders on
          time. Whether it’s custom designs or bulk production, we make sure the
          process is smooth and fast so you can focus on your business goals.
        </p>
      </div>
    </div>
  </section>
  </>
);

export default WhyChooseUs;
