import React, { useState, useRef, useEffect } from "react";
import "../Header/Header.css";
import SchoolImagesNames from "../SchoolImagesNames";
import SportImageNames from "../SportImagesNames";
import LazyLoad from "react-lazyload";

const sportImageBaseUrl = process.env.REACT_APP_IMAGE_SPORT_URL;
const schoolImageBaseUrl = process.env.REACT_APP_IMAGE_SCHOOL_URL;

const products = [];

// Populate the products list
SchoolImagesNames.map((imageName) => {
  products.push({
    title: imageName,
    image: `${schoolImageBaseUrl}${imageName}`,
    category: "School",
  });
});

SportImageNames.map((imageName) => {
  products.push({
    title: imageName,
    image: `${sportImageBaseUrl}${imageName}`,
    category: "Sports",
  });
});

function SearchBar() {
  const [_products, setProducts] = useState(products);
  const [searchVal, setSearchVal] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const searchRef = useRef(null); // Reference for the search bar container

  // Filter products based on search value
  const filteredProducts = searchVal
    ? _products.filter((item) => {
        if (item.title.toLowerCase().includes(searchVal.toLowerCase())) {
          return item;
        } else if (
          item.category.toLowerCase().includes(searchVal.toLowerCase())
        ) {
          return item;
        }
      })
    : _products;

  // Handle product selection
  const handleProductClick = (product) => {
    setSelectedProduct(product);
    setShowPopup(true);
    setSearchVal("");
  };

  // Close popup modal
  const closeModal = () => {
    setSelectedProduct(null);
  };

  // Handle clicks outside the search bar to close suggestions
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setSearchVal("");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="search-bar" ref={searchRef}>
      <input
        type="text"
        placeholder="Search"
        onChange={(e) => setSearchVal(e.target.value)}
        value={searchVal}
      />

      {/* Suggestion box */}
      {searchVal && (
        <div
          className={`suggestion-box ${
            filteredProducts.length > 0 ? "active" : ""
          }`}
        >
          {filteredProducts.length > 0 ? (
            filteredProducts.map((product, index) => (
              <div
                key={index}
                className="suggestion-item"
                onClick={() => handleProductClick(product)}
              >
                <img
                  src={product.image}
                  alt={product.title}
                  className="product-image"
                  onError={(e) =>
                    (e.target.src = "/path/to/placeholder.jpg")
                  }
                />
                <div className="product-details">
                  <div className="product-title">{product.title}</div>
                </div>
              </div>
            ))
          ) : (
            <div className="no-results">No results found</div>
          )}
        </div>
      )}

      {/* Popup showing product details */}
      {showPopup && selectedProduct && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <LazyLoad height={400} offset={100}>
              <img
                src={selectedProduct.image}
                alt={selectedProduct.title}
                className="modal-image"
              />
            </LazyLoad>
            <p className="modal-title">{selectedProduct.title}</p>
            <button className="modal-button" onClick={closeModal}>
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default SearchBar;
