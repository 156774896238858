import React, { useState, useContext, useEffect } from "react";
import vector from "../assets/images/Vector.png";
import vector1 from "../assets/images/Vector1.png";
import LazyLoad from "react-lazyload"; // Lazy loading for images
import ContactUs from "../Components/ContactUs/ContactUs";
import { UserContext } from "../App"; // Import UserContext

const Festival = () => {
  const { _contacted, setCurrentRoute } = useContext(UserContext);

  useEffect(() => {
    setCurrentRoute("Festival");
    window.scrollTo(0, 0);
  }, []);

  const [filter, setFilter] = useState("All");
  const [selectedItem, setSelectedItem] = useState(null);
  const [showContactUs, setShowContactUs] = useState(true); // Contact form
  const [thankYouPopup, setThankYouPopup] = useState(false); // Thank-you popup

  // Categories
  const categories = ["All", "Sports", "Navratri", "Ganapati", "Holi"];

  // Items array
  const items = [
    {
      title: "Sports Jersey 1",
      image: "https://example.com/sports1.jpg",
      category: "Sports",
    },
    {
      title: "Navratri Dress",
      image: "https://example.com/navratri1.jpg",
      category: "Navratri",
    },
    {
      title: "Ganapati Outfit",
      image: "https://example.com/ganapati1.jpg",
      category: "Ganapati",
    },
    {
      title: "Holi T-shirt",
      image: "https://example.com/holi1.jpg",
      category: "Holi",
    },
    {
      title: "Sports Jersey 2",
      image: "https://example.com/sports2.jpg",
      category: "Sports",
    },
  ];

  // Filtered items based on selected category
  const filteredItems =
    filter === "All" ? items : items.filter((item) => item.category === filter);

  // Open and close modal functions
  const openModal = (item) => setSelectedItem(item);
  const closeModal = () => setSelectedItem(null);

  // Handle thank-you popup logic
  const handleThankYou = () => {
    setThankYouPopup(true);
    setTimeout(() => {
      setThankYouPopup(false);
    }, 1500); // Display for 1.5 seconds
  };

  return (
    <>
      <div className="app">
        {!_contacted && (
          <ContactUs
            close={() => {
              setShowContactUs(false);
              handleThankYou();
            }}
          />
        )}
        <h1 className="title">
          <img src={vector} alt="" /> FESTIVAL <img src={vector1} alt="" />
        </h1>

        {/* Filter Section */}
        <div className="filter-buttons">
          {categories.map((category) => (
            <button
              key={category}
              className={`filter-button ${filter === category ? "active" : ""}`}
              onClick={() => setFilter(category)}
            >
              {category}
            </button>
          ))}
        </div>

        {/* Grid Section */}
        <div className="grid-container">
          {filteredItems.map((item, index) => (
            <div
              className="grid-item"
              key={index}
              onClick={() => openModal(item)}
            >
              <LazyLoad height={200} offset={100}>
                <img src={item.image} alt={item.title} className="item-image" />
              </LazyLoad>
              <p className="item-title">{item.title}</p>
            </div>
          ))}
        </div>

        {/* Modal Section */}
        {selectedItem && (
          <div className="modal-overlay" onClick={closeModal}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
              <LazyLoad height={400} offset={100}>
                <img
                  src={selectedItem.image}
                  alt={selectedItem.title}
                  className="modal-image"
                />
              </LazyLoad>
              <p className="modal-title">{selectedItem.title}</p>
              <button className="modal-button" onClick={closeModal}>
                Close
              </button>
            </div>
          </div>
        )}

        {/* Thank You Popup */}
        {thankYouPopup && (
          <div className="thank-you-popup">
            <p>Thank you! Your message has been sent.</p>
          </div>
        )}
      </div>
    </>
  );
};

export default Festival;
