import React, { useContext, useEffect } from "react";
import WhyChooseUs from "./WhyChooseUs";
import Testimonials from "./Testimonials";
import Hero from "./Hero/Hero"; // Correct relative path
import { UserContext } from "../App"; // Import UserContext

function Index() {
  const { _contacted, setCurrentRoute } = useContext(UserContext);

  useEffect(() => {
    setCurrentRoute("Home");
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <>
      <Hero />
      <WhyChooseUs />
      <Testimonials />
    </>
  );
}

export default Index;
