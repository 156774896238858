import React, { useState, createContext, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Index from "./Components/Index";
import Sports from "./Components/Sports";
import School from "./Components/School";
import Festival from "./Components/Festivals";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer";

export const UserContext = createContext();

function App() {
  const [_contacted, setContacted] = useState(false); // Initialize state
  const [_currentRoute, setCurrentRoute] = useState("/");

  // Disable right-click globally
  useEffect(() => {
    const disableRightClick = (e) => {
      e.preventDefault();
    };
    document.addEventListener("contextmenu", disableRightClick);

    // Cleanup on unmount
    return () => {
      document.removeEventListener("contextmenu", disableRightClick);
    };
  }, []);

  // Initialize contact state from localStorage
  useEffect(() => {
    setContacted(localStorage.getItem("Contacted"));
  }, []);

  return (
    <UserContext.Provider
      value={{ _contacted, setContacted, _currentRoute, setCurrentRoute }}
    >
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/Home" element={<Index />} />
          <Route path="/Sports" element={<Sports />} />
          <Route path="/School" element={<School />} />
          <Route path="/Festival" element={<Festival />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </UserContext.Provider>
  );
}

export default App;
