import React, { useState, useContext } from "react";
import emailjs from "emailjs-com"; // Import EmailJS SDK
import personWithLaptop from "../../assets/images/contact-us.png";
import "./ContactUs.css";
import { UserContext } from "../../App"; // Import UserContext

const ContactUs = ({ close = () => {} }) => {
  const { _contacted, setContacted } = useContext(UserContext);

  // State to capture form data
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    message: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false); // Success message state

  // Function to handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
  
    if (name === "firstName" || name === "lastName") {
      const updatedValue = value.replace(/[^A-Za-z\s]/g, "");  // Replace anything that's not a letter or space
      setFormData((prevData) => ({
        ...prevData,
        [name]: updatedValue,
      }));
    } else if (name === "phone") {
      const updatedValue = value.replace(/[^0-9]/g, "").slice(0, 10);  // Allow only numbers and limit length to 10
      setFormData((prevData) => ({
        ...prevData,
        [name]: updatedValue,
      }));
    } else if (name === "email") {
      // Ensure the first character is not a number and allow valid email characters (letters, numbers, and specific symbols like @, ., _, and -)
      const firstChar = value.charAt(0);
      if (!isNaN(firstChar) && firstChar !== "") {
        return;  // Prevent input if the first character is a number
      }
      
      const updatedValue = value.replace(/[^A-Za-z0-9@._-]/g, "");
      setFormData((prevData) => ({
        ...prevData,
        [name]: updatedValue,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  
  // Function to validate phone number (exactly 10 digits)
  const isPhoneValid = (phone) => {
    const phoneRegex = /^[0-9]{10}$/; // Only 10 digits allowed
    return phoneRegex.test(phone);
  };

  // Function to validate email format
  const isEmailValid = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/; // Email format regex
    return emailRegex.test(email);
  };

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate first name (should not contain numbers or symbols)
    if (!formData.firstName.trim()) {
      alert("First name is required and should not contain numbers or symbols.");
      return; // Prevent form submission if validation fails
    }

    // Validate phone number (only 10 digits)
    if (!isPhoneValid(formData.phone)) {
      alert("Please enter a valid 10-digit phone number.");
      return; // Prevent form submission if validation fails
    }

    // Validate email (proper email format)
    if (!isEmailValid(formData.email)) {
      alert("Please enter a valid email address.");
      return; // Prevent form submission if validation fails
    }

    setIsSubmitting(true); // Indicate form submission is in progress

    // Access environment variables with the correct prefix
    const userID = process.env.REACT_APP_EMAILJS_USER_ID; // Correct prefix
    const serviceID = process.env.REACT_APP_EMAILJS_SERVICE_ID; // Correct prefix
    const templateID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID; // Correct prefix

    // EmailJS API Call
    emailjs
      .send(
        serviceID,
        templateID,
        {
          name: `${formData.firstName} ${formData.lastName}`,
          email: formData.email,
          phone: formData.phone,
          message: formData.message,
        },
        userID
      )
      .then(
        (response) => {
          console.log("Message sent successfully", response);
          setFormData({
            firstName: "",
            lastName: "",
            phone: "",
            email: "",
            message: "",
          });
          setShowSuccessMessage(true);
          localStorage.setItem("Contacted", true);
          setContacted(true);

          setTimeout(() => {
            close(false);
            setShowSuccessMessage(false);
          }, 2000);
        },
        (error) => {
          console.error("Failed to send message", error);
          alert("Failed to send message. Please try again later.");
        }
      )
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <div className="popup-header">
          <div className="popup-title">
            <p className="contact-text">
              <span>Get in </span>
              <span>touch </span>
              <span>with us</span>
            </p>
          </div>
          <div className="popup-image">
            <img src={personWithLaptop} alt="Support Representative" />
          </div>
        </div>
        <form onSubmit={handleSubmit} className="contact-form">
          <div className="form-group">
            <input
              type="text"
              placeholder="First name"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              placeholder="Last name"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <input
              type="tel"
              placeholder="Contact no."
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
            <input
              type="email"
              placeholder="Email id"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <textarea
            placeholder="Message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            rows="4"
          />
          <button type="submit" className="submit-btn" disabled={isSubmitting}>
            {isSubmitting ? "Sending..." : "Contact us"}
          </button>
        </form>
        <button
          onClick={() => close(false)}
          className={`close-popup-btn ${_contacted ? "flex" : "display-none"}`}
        >
          &#10005;
        </button>
      </div>

      {/* Success Message Popup */}
      {showSuccessMessage && (
        <div className="success-message-overlay">
          <div className="success-message">
            <p>Message sent successfully!</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactUs;
