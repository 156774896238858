import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import Link from react-router-dom
import texture from "../../assets/images/texture.png";
import vector from "../../assets/images/Vector.png";
import vector1 from "../../assets/images/Vector1.png";
import "./Hero.css";
import ContactUs from "../ContactUs/ContactUs";
import SportImagesNames from "../SportImagesNames";
import SchoolImagesNames from "../SchoolImagesNames";

const Hero = () => {
  const navigate = useNavigate();
  const [_contactUsOpen, setContactUsOpen] = useState(false);

  const handleCloseContactUs = () => {
    setContactUsOpen(false);
  };

  const sportImageBaseUrl = process.env.REACT_APP_IMAGE_SPORT_URL;
  const schoolImageBaseUrl = process.env.REACT_APP_IMAGE_SCHOOL_URL;
  const festivalImageBaseUrl = process.env.REACT_APP_IMAGE_SPORT_URL;

  const sportImages = SportImagesNames.slice(0, 4).map((imageName, index) => ({
    url: `${sportImageBaseUrl}${imageName}`,
    title: `SG-${index + 1}`,
  }));
  const schoolImages = SchoolImagesNames.slice(0, 4).map(
    (imageName, index) => ({
      url: `${schoolImageBaseUrl}${imageName}`,
      title: `School-${index + 1}`,
    })
  );
  const festivalImages = SportImagesNames.slice(0, 4).map(
    (imageName, index) => ({
      url: `${festivalImageBaseUrl}${imageName}`,
      title: `Festival-${index + 1}`,
    })
  );

  const collections = [
    { name: "Sports", images: sportImages, link: "/sports" },
    { name: "School", images: schoolImages, link: "/school" },
    { name: "Festival", images: festivalImages, link: "/festival" },
  ];

  return (
    <>
      <section
        className="hero"
        style={{
          backgroundImage: `url(${texture})`,
        }}
      >
        <h1>
          Specialized Garment Solutions for
          <br /> Sports, Schools and Festivals
        </h1>
        <p>
          Welcome to Shri Shankar Garment, your one-stop destination for bulk
          garment solutions. <br /> With a commitment to quality, affordability,
          and timely delivery, we specialize in <br /> crafting sportswear,
          school uniforms, and festival apparel.
        </p>
        <button onClick={() => setContactUsOpen(true)}>Contact Us</button>

        <div className="">
          <div className="section-title">
            <h2>
              <img src={vector} alt="" /> Our Collection{" "}
              <img src={vector1} alt="" />
            </h2>
          </div>
          <div className="collection-grid">
            {collections.map((collection, colIndex) => (
              <div
                onClick={() => navigate(collection.link)}
                key={colIndex}
                className="collection-tile"
              >
                <div className="img-grid">
                  {collection.images.map((image, imgIndex) => (
                    <div className="product-item" key={imgIndex}>
                      <img src={image.url} alt={image.title} />
                    </div>
                  ))}
                </div>
                <p className="collection-title">{collection.name}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {_contactUsOpen && <ContactUs close={handleCloseContactUs} />}
    </>
  );
};

export default Hero;
