import React, { useContext, useState } from "react";
import "./NavBar.css";
import { useNavigate } from "react-router-dom";
import ContactUs from "../ContactUs/ContactUs";
import { UserContext } from "../../App";

const NavBar = (props) => {
  const { _currentRoute, setCurrentRoute } = useContext(UserContext);
  const navigate = useNavigate();
  const { className } = props;
  const [_isActive, setTabActive] = useState("Home"); // Track active tab
  const [_contactUsOpen, setContactUsOpen] = useState(false); // Track Contact Us modal

  const handleClick = (collectionName) => {
    navigate({ pathname: collectionName }); // Navigate to the route
    setTabActive(collectionName); // Set the active tab
    setCurrentRoute(collectionName);
  };

  const handleOpenContactUs = () => {
    setContactUsOpen(true); // Open the Contact Us modal
    setTabActive("Contact Us"); // Set "Contact Us" as the active tab
  };

  const handleCloseContactUs = () => {
    setContactUsOpen(false); // Close the Contact Us modal
    setTabActive("Home"); // Optionally set "Home" as active after closing
  };

  return (
    <>
      <nav className={`navbar ${className}`}>
        <div className="menu">
          <div
            className={`nav-link ${(_currentRoute === "Home" || _currentRoute === "/") ? "active" : ""}`}
            onClick={() => handleClick("Home")}
          >
            Home
          </div>
          <div
            className={`nav-link ${_currentRoute === "Sports" ? "active" : ""}`}
            onClick={() => handleClick("Sports")}
          >
            Sports
          </div>
          <div
            className={`nav-link ${_currentRoute === "School" ? "active" : ""}`}
            onClick={() => handleClick("School")}
          >
            School
          </div>
          <div
            className={`nav-link ${_currentRoute === "Festival" ? "active" : ""}`}
            onClick={() => handleClick("Festival")}
          >
            Festival
          </div>
          <div
            className={`nav-link ${
              _isActive === "Contact Us" ? "active" : ""
            }`}
            onClick={handleOpenContactUs} // Open Contact Us modal
          >
            Contact Us
          </div>
        </div>
      </nav>
      {_contactUsOpen && <ContactUs close={handleCloseContactUs} />}
    </>
  );
};

export default NavBar;
