import React, { useContext, useState } from "react";
import "./Header.css";
import logo from "../../assets/images/logo.png";
import skLogo from "../../assets/images/sk-logo.png";
import NavBar from "../NavBar/NavBar";
import { useNavigate } from "react-router-dom";
import ContactUs from "../ContactUs/ContactUs";
import SearchBar from "../SearchBar/Searchbar";
import { UserContext } from "../../App";

const Header = () => {
  const { _currentRoute } = useContext(UserContext);
  const navigate = useNavigate();
  const [_contactUsOpen, setContactUsOpen] = useState(false);
  const [_menuOpen, setMenuOpen] = useState(false);
  const [_closing, setClosing] = useState(false);

  const handleClick = (collectionName) => {
    navigate(`/${collectionName}`);
    closeMenu();
  };

  const closeMenu = () => {
    setClosing(true);
    setMenuOpen(false);
    setClosing(false);
  };

  const toggleMenu = () => {
    if (_menuOpen) {
      closeMenu();
    } else {
      setMenuOpen(true);
    }
  };

  return (
    <>
      <div
        className={`overlay ${_menuOpen && !_closing ? "visible" : ""}`}
        onClick={closeMenu}
      ></div>

      <header className={`_menuOpen ? "relative" : "sticky"`}>
        <div className="hamburger" onClick={toggleMenu}>
          <span className={_menuOpen ? "active" : ""}></span>
          <span className={_menuOpen ? "active" : ""}></span>
          <span className={_menuOpen ? "active" : ""}></span>
        </div>
        <div
          className={`sidebar ${_menuOpen ? "open" : ""} ${
            _closing ? "_closing" : ""
          }`}
        >
          {["Home", "Sports", "School", "Festival"].map((tab) => (
            <div
              key={tab}
              className={`nav-link ${
                _currentRoute === "/" && tab === "Home" && "active"
              } ${_currentRoute === tab ? "active" : ""}`}
              onClick={() => handleClick(tab)}
            >
              {tab}
            </div>
          ))}
          <div
            className="nav-link"
            onClick={() => {
              setContactUsOpen(true);
              closeMenu();
            }}
          >
            Contact Us
          </div>
        </div>

        <div className="logo">
          <img src={logo} alt="Shri Shankar Garment Logo" />
        </div>
        <SearchBar />
        {/* <div className="search-bar">
          <input type="text" placeholder="Search" />
        </div> */}
        <NavBar />
        <div className="sk-logo">
          <img src={skLogo} alt="SK Logo" />
        </div>
      </header>
      {_contactUsOpen && <ContactUs close={() => setContactUsOpen(false)} />}
      {/* <SearchBar/>  */}
    </>
  );
};

export default Header;
