// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar {
  display: flex;
  align-items: center;
  padding: 0px 20px;
  background-color: white;
}

.logo {
  font-size: 20px;
  font-weight: bold;
}

.menu {
  display: flex;
  gap: 20px;
}

.nav-link {
  padding: 10px 10px;
  align-content: center;
  text-decoration: none;
  font-size: 16px;
  color: #828282;
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: #ff6600;
  cursor: pointer;
}

.nav-link.active {
  color: black;
  font-weight: bold;
  border-bottom: 3px solid #ff6600;
}

@media (max-width: 992px) {
  .navbar{
    display: none;
  }
}

@media (max-width: 768px) {
  .navbar {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/NavBar/NavBar.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,iBAAiB;EACjB,uBAAuB;AACzB;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,qBAAqB;EACrB,eAAe;EACf,cAAc;EACd,2BAA2B;AAC7B;;AAEA;EACE,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,iBAAiB;EACjB,gCAAgC;AAClC;;AAEA;EACE;IACE,aAAa;EACf;AACF;;AAEA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":[".navbar {\r\n  display: flex;\r\n  align-items: center;\r\n  padding: 0px 20px;\r\n  background-color: white;\r\n}\r\n\r\n.logo {\r\n  font-size: 20px;\r\n  font-weight: bold;\r\n}\r\n\r\n.menu {\r\n  display: flex;\r\n  gap: 20px;\r\n}\r\n\r\n.nav-link {\r\n  padding: 10px 10px;\r\n  align-content: center;\r\n  text-decoration: none;\r\n  font-size: 16px;\r\n  color: #828282;\r\n  transition: color 0.3s ease;\r\n}\r\n\r\n.nav-link:hover {\r\n  color: #ff6600;\r\n  cursor: pointer;\r\n}\r\n\r\n.nav-link.active {\r\n  color: black;\r\n  font-weight: bold;\r\n  border-bottom: 3px solid #ff6600;\r\n}\r\n\r\n@media (max-width: 992px) {\r\n  .navbar{\r\n    display: none;\r\n  }\r\n}\r\n\r\n@media (max-width: 768px) {\r\n  .navbar {\r\n    display: none;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
