import React, { useEffect, useRef } from "react";
import vector from "../assets/images/Vector.png";
import vector1 from "../assets/images/Vector1.png";
// Import your avatar image
import avatarImage from "../assets/images/avatar.png"; 

const testimonials = [
  {
    name: "Sneha Jain",
    location: "HBSC School, Pune",
    feedback:
      "The school uniforms we ordered were perfect! The stitching was neat, and the fabric was soft for kids. Highly recommend!",
  },
  {
    name: "Ravi Mehta",
    location: "XYZ Co., Mumbai",
    feedback:
      "Exceptional service! Our corporate uniforms were delivered on time, and the quality was outstanding.",
  },
  {
    name: "Anita Singh",
    location: "Greenfield Academy, Delhi",
    feedback:
      "We loved the customization options! The kids were so happy with their uniforms.",
  },
  {
    name: "Rahul Khanna",
    location: "Metro High School, Bangalore",
    feedback:
      "Excellent customer support and top-notch fabric quality. Would recommend to everyone!",
  },
  {
    name: "Anita Singh",
    location: "Greenfield Academy, Delhi",
    feedback:
      "We loved the customization options! The kids were so happy with their uniforms.",
  },
  {
    name: "Rahul Khanna",
    location: "Metro High School, Bangalore",
    feedback:
      "Excellent customer support and top-notch fabric quality. Would recommend to everyone!",
  },
];

const Testimonials = () => {
  const sliderRef = useRef(null);

  useEffect(() => {
    const slider = sliderRef.current;
    let scrollAmount = 0;

    const autoScroll = () => {
      if (slider) {
        scrollAmount += 1; // Adjust speed by changing the increment value
        slider.scrollLeft = scrollAmount;

        // Reset scrolling when it reaches the end
        if (scrollAmount >= slider.scrollWidth - slider.clientWidth) {
          scrollAmount = 0;
        }
      }
    };

    const interval = setInterval(autoScroll, 20); // Adjust interval for smoothness
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="testimonials-container">
      <h2>
        <img src={vector} alt="" /> What Our Clients Say{" "}
        <img src={vector1} alt="" />
      </h2>
      <div className="testimonials-list" ref={sliderRef}>
        {testimonials.map((testimonial, index) => (
          <div className="testimonial-card" key={index}>
            {/* Displaying the avatar image */}
            <div className="testimonial-avatar">
              <img src={avatarImage} alt={`${testimonial.name}'s avatar`} />
            </div>
            <div className="testimonial-content">
              <h4>{testimonial.name}</h4>
              <p className="location">{testimonial.location}</p>
              <p className="feedback">"{testimonial.feedback}"</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Testimonials;
