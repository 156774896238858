import React from 'react'
import logo from '../assets/images/logo.png';
import skLogo from '../assets/images/sk-logo.png';
import facebookIcon from '../assets/images/facebook.png';
import instagramIcon from '../assets/images/instagram.png';


function Footer() {
  return (
    <>
        {/* footer */}
	<footer className="footer">
	<div className="footer-container">
      <div className="footer-logo">
        <img src={logo} alt="Shri Shankar Garment Logo" />
        {/* <p>Shri Shankar Garment</p> */}
      </div>
      <div className="footer-socials">
     <h3>Our Socials</h3>
  <div className="social-icons">
    <div className="social-item">
      <img src={instagramIcon} alt="Instagram" />
      <a href="https://www.instagram.com/shankargarment_555?igsh=MWJjYnh0OWFrY3djbQ==">Instagram</a>
    </div>
    <div className="social-item">
      <img src={facebookIcon} alt="Facebook" />
      <a href="https://www.facebook.com/shankarlimbitote/">Facebook</a>
    </div>
  </div>
</div>

      <div className="footer-address">
        <h3>Office Address</h3>
        <p>
          C 27/B, Naganth Laghu Udyog Society, Akkalkot Road, MIDC, Solapur - 413006, Maharashtra, India.
          9021236858 / 9665466052
        </p>
      </div>
      <div className="footer-sk-logo">
        <img src={skLogo} alt="SK Sports Logo" />
      </div>
    </div>
  	</footer>
	{/* end footer */}
    </>
  )
}

export default Footer
