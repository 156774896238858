const SchoolImagesNames = [
  "sport-1138.jpg",
  "sport-1162.jpg",
  "sport-1166.jpg",
  "sport-1168.jpg",
  "sport-1336.jpg",
  "sport-1340.jpg",
  "sport-35-1.jpg",
  "sport-35-2.jpg",
  "sport-35-3.jpg",
  "sport-35-4.jpg",
  "sport-kv gren.jpg",
  "sport-kv111.jpg",
  "sport-kv12.jpg",
  "sport-kv356.jpg",
  "sport-mockup.jpg",
  "sport-PC2-1.jpg",
  "sport-SU28.jpg",
];

export default SchoolImagesNames;
