import React, { useState, useContext, useEffect } from "react";
import LazyLoad from "react-lazyload"; // Import LazyLoad
import SportImagesNames from "./SportImagesNames";
import ContactUs from "../Components/ContactUs/ContactUs";
import vector from "../assets/images/Vector.png";
import vector1 from "../assets/images/Vector1.png";
import { UserContext } from "../App"; // Import UserContext

const Sports = () => {
  const { _contacted, setCurrentRoute } = useContext(UserContext);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    setCurrentRoute("Sports");
    window.scrollTo(0, 0);
  }, []);

  // Generate URLs dynamically using the environment variable
  const imageBaseUrl = process.env.REACT_APP_IMAGE_SPORT_URL; // Access the image base URL from .env
  const items = SportImagesNames.map((imageName) => {
    const match = imageName.match(/\d+/);
    const imageNumber = match ? match[0] : "Unknown"; // Fallback to 'Unknown' if no match
    return {
      title: `SG-${imageNumber}`,
      image: `${imageBaseUrl}${imageName}`,
      category: "Sports",
    };
  });

  const openModal = (item) => {
    setSelectedItem(item);
  };

  const closeModal = () => {
    setSelectedItem(null);
  };

  return (
    <>
      <div className="app">
        <h1 className="title">
          <img src={vector} alt="" /> SPORTS <img src={vector1} alt="" />
        </h1>
        <div className={`grid-container ${!_contacted ? "blur" : ""}`}>
          {items.map((item, index) => (
            <div
              className="grid-item"
              key={index}
              onClick={() => openModal(item)}
            >
              <LazyLoad height={200} offset={100}>
                {" "}
                {/* Lazy load with custom height and offset */}
                <img src={item.image} alt={item.title} className="item-image" />
              </LazyLoad>
              <p className="item-title">{item.title}</p>
            </div>
          ))}
        </div>

        {selectedItem && (
          <div className="modal-overlay" onClick={closeModal}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
              <LazyLoad height={400} offset={100}>
                <img
                  src={selectedItem.image}
                  alt={selectedItem.title}
                  className="modal-image"
                />
              </LazyLoad>
              <p className="modal-title">{selectedItem.title}</p>
              <button className="modal-button" onClick={closeModal}>
                Close
              </button>
            </div>
          </div>
        )}
      </div>
      {!_contacted && <ContactUs />}
    </>
  );
};

export default Sports;
